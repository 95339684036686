@import url('https://fonts.googleapis.com/css2?family=DM+Mono:ital@0;1&family=Open+Sans&family=Source+Code+Pro&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  @apply h-full;
  scroll-behavior: smooth;
}
body {
  @apply h-full;
  background-color: #1D202D;
}
div#__next {
  @apply h-full;
}
main {
  @apply h-full;
}
#root {
  height: 100vh;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

section{
    border-bottom-width:1px;
    --tw-border-opacity:1;
    border-color:#29293d;
    padding-top:3.5rem;
    padding-bottom:3.5rem
}

 

.container{
    width:100%;
    margin-right:auto;
    margin-left:auto;
    padding-right:1.2rem;
    padding-left:1.2rem;
}
@media(min-width:500px){
    .container{
      max-width:500px;
    }
}
@media(min-width:768px){
    .container{
        max-width:768px
    }
}
@media(min-width:1024px){
    .container{
        max-width:1024px;
        padding-right:3rem;
        padding-left:3rem;
    }
}
@media(min-width:1280px){
    .container{
        max-width:1280px;
        padding-right:3rem;
        padding-left:3rem;
    }
}
@media(min-width:1536px){
    .container{
        max-width:1536px;
        padding-right:3rem;
        padding-left:3rem;
    }
}
 
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
